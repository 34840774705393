/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

body {
    font-family: 'Roboto', sans-serif;
    color: #36adc1;
    min-width: 320px;
    overflow-x: hidden;
}
.d-none {
    display: none!important;
}
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

input, button:focus {
    outline: none;
}

a {
    text-decoration: none;
}

li {
    list-style-type: none;
}

/* Sticky footer styles
-------------------------------------------------- */
.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
    font-size: 13px;
}

.list {
    overflow-x: auto;
}

.list-event {
    table-layout: fixed;
}

.list-event th {
    word-wrap: break-word;
    font-size: 0.95rem;
    font-weight: 600;
    padding: 5px;
}

.row-event {
    table-layout: fixed;
}

.row-event td {
    word-wrap: break-word;
    font-size: 0.9rem;
    border-top: 1px solid #ddd;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
}


/* ---------------------------------------------------
    LAYOUT
----------------------------------------------------- */

header {
    grid-area: header;
}

aside {
    grid-area: sidebar;
}

main {
    grid-area: main;
}

footer {
    grid-area: footer;
}

.report-status-text.submitted {
    color: #62DA9A;
    font-weight: 900;
}
.report-status-text.in_review {
    color: #FFC173;
    font-weight: 900;
}

/* Стили для devexpress */

.report-input .dx-texteditor {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    border-radius: 0;
}
.report-input .dx-texteditor .dx-texteditor-input {
    font-weight: 700;
}
.dx-editor-outlined.report-input {
    border-radius: 0;
    border-style: none;
}
.dx-editor-outlined.dx-state-readonly.report-input {
    background: #b2b2b2;
    border-radius: 0;
    border-style: none;
}
.dx-texteditor {
    height: calc(1.5em + .5rem + 2px);
}
.dx-texteditor-input {
    height: 100%!important;
    min-height: initial!important;
}
div#viewDatepicker {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.field-group > .dx-texteditor {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.dx-texteditor.dx-editor-outlined {
    border-radius: 0;
}
/* Конец блока */


h3 {
    font-family: "Roboto" !important;
    font-weight: lighter;
}

.menu-treegrid {
    min-width: 300px;
    cursor: pointer;
    color: #fff;
}
.menu-treegrid[aria-expanded="true"], .menu-treegrid:hover {
    background-color: #003a51!important;
}
.menu-treegrid[aria-expanded="false"] {
    background-color: transparent;
}

.dx-popup-wrapper > .dx-overlay-content {
    background: #052940;
    border: none;
    border-radius: 0;
    overflow-y: auto;
}
.dx-popup-wrapper .dx-state-focused.dx-overlay-content {
    border: none;
}
.dx-popup-content > .dx-button {
    margin: 0!important;
}
.dx-button-mode-contained {
    background-color: #03405b;
    border-color: transparent;
    color: #fff;
}
.dx-button-mode-contained.dx-state-hover {
    background-color: #0b4863;
    border-color: transparent;
}
.dx-datagrid-header-panel .dx-toolbar {
    margin: 0;
    background-color: #052940;
}
.dx-datagrid .dx-row-lines > td {
    border-bottom: none;
}
.dx-datagrid-borders > .dx-datagrid-rowsview, .dx-datagrid-borders > .dx-datagrid-total-footer {
    border-bottom: none;
}
.dx-datagrid-borders > .dx-datagrid-headers, .dx-datagrid-borders > .dx-datagrid-rowsview, .dx-datagrid-borders > .dx-datagrid-total-footer {
    border-left: none;
    border-right: none;
}
.dx-datagrid-borders > .dx-datagrid-filter-panel, .dx-datagrid-borders > .dx-datagrid-headers, .dx-datagrid-borders > .dx-datagrid-pager {
    border-top: none;
}
.dx-gridbase-container {
    height: auto;
}
.dx-placeholder:before {
    padding: 4px 9px 8px;
}
#wellServicesGrid {
    max-height: calc(100vh - 145px);
}